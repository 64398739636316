define("commander/pods/components/contract-led/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "hqtr7RB+",
    "block": "{\"symbols\":[\"@contract\"],\"statements\":[[10,\"div\"],[14,0,\"led\"],[15,5,[31,[\"background-color: \",[32,0,[\"contractColor\"]]]]],[15,\"title\",[31,[[30,[36,0],[[32,1],[32,0,[\"contracts\"]]],null]]]],[12],[13]],\"hasEval\":false,\"upvars\":[\"contract-name\"]}",
    "meta": {
      "moduleName": "commander/pods/components/contract-led/template.hbs"
    }
  });
});