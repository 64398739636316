define("commander/helpers/contract-name", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.contractName = contractName;
  _exports.default = void 0;
  function contractName(params) {
    const contract = params[0];
    const contracts = params[1];
    const found = (contracts || []).find(c => c.key === contract);
    return found ? `${found.name} (${contract})` : contract;
  }
  var _default = _exports.default = Ember.Helper.helper(contractName);
});