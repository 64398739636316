define("commander/abstract/items-filter", ["exports", "@glimmer/component", "commander/config/environment"], function (_exports, _component, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const getCapacityIds = capacities => capacities.filter(i => i.count > 0).map(i => i.unitId);
  let ItemsFilter = _exports.default = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember.computed('appliedFilters.length', 'args.items'), _dec4 = Ember._action, _dec5 = Ember._action, (_class = class ItemsFilter extends _component.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "filters", _descriptor, this);
      _initializerDefineProperty(this, "appliedFilters", _descriptor2, this);
      _defineProperty(this, "lastFilteredItems", []);
      _defineProperty(this, "visibleTimeThreshold", _environment.default.APP.visibleTimeThreshold);
    }
    get items() {
      console.debug('filters or data changed, refreshing items');
      if (!this.appliedFilters.length) {
        return this.args.items;
      }

      // this.appliedFilters containing object should have:
      //  - type - value should match property name of the object that is filtered
      //  - value - search value to compare for, can be an array
      const filtersObj = this.appliedFilters.reduce((a, i) => ({
        ...a,
        [i.type]: [...(a[i.type] || []), i.value]
      }), {});
      // iterate through each filter and check if an appropriate property equals to one of the values of the filter
      // item - item in array to be filtered (route/trip/vehicle/etc.)
      // name - key from applied appliedFilters list, generated above from original appliedFilters type
      // prop - value (or object) from the object filtered
      const filtered = this.args.items.filter(item => Object.keys(filtersObj).reduce(
      // test each filter against the item
      (a, name) => {
        const propFilters = filtersObj[name];
        const prop = item[name]; // value from object
        const itemId = item.get('id');
        const applyVisibleThreshold = this.lastFilteredItems.find(item => item.id === itemId);
        const animation = (this.visibleTimeThreshold[name] || {}).animate || 'heartbeat';
        const oldPropValue = applyVisibleThreshold ? applyVisibleThreshold[name] : prop;
        if (applyVisibleThreshold && this.visibleTimeThreshold[name] && oldPropValue !== prop) {
          // start animation
          setTimeout(() => {
            this.lastFilteredItems = this.lastFilteredItems.filter(id => id === itemId);
            this.apply();
            item.set('animate', 'no-animation');
            // stop animation
          }, this.visibleTimeThreshold[name].duration * 1000);
          item.set('animate', animation);
          return true;
        }
        if (item.get('animate') === animation) {
          // if animation ongoing -> let it finish
          return true;
        }
        if (Array.isArray(prop)) {
          // for capacities it is nested array
          const ids = prop.map(arrayItem => Array.isArray(arrayItem) ? getCapacityIds(arrayItem) : arrayItem.id).flat();
          if (name === 'contract') {
            const lowercaseProp = prop.map(p => p.toLowerCase());
            return a && propFilters.some(f => lowercaseProp.some(i => i.includes(f.toLowerCase())));
          } else {
            return a && propFilters.every(f => ids.includes(+f)); // match each capacity search option
          }
        } else if (prop instanceof Object) {
          // filtered property is object
          return a && propFilters.some(f => prop[f]);
        } else {
          // string filtering
          return a && propFilters.some(f => `${prop}`.toLowerCase().includes(`${f}`.toLowerCase()));
        }
      }, true));
      this.lastFilteredItems = filtered.map(item => ({
        id: item.get('id'),
        ...Object.keys(this.visibleTimeThreshold).reduce((acc, curr) => {
          acc[curr] = item[curr];
          return acc;
        }, {})
      }));
      return filtered;
    }
    applyFilter(type, value) {
      console.log('applyFilter', type, value);
      if (typeof value === 'boolean') {
        this.filters[type] = value;
        if (value) {
          this.appliedFilters.push({
            type: type,
            label: '',
            value: true
          });
        } else {
          this.appliedFilters = this.appliedFilters.filter(i => i.type !== type);
        }
      } else if (this.filters[type] instanceof Object) {
        const index = this.appliedFilters.findIndex(i => `${i.type}` === `${type}` && `${i.value}` === `${value}`);
        if (index !== -1) {
          this.appliedFilters.splice(index, 1);
        } else {
          const filter = this.filters[type][value];
          filter.checked = !filter.checked;
          this.appliedFilters.push({
            type: type,
            value: filter.value,
            label: filter.name
          });
        }
      } else {
        // ignore, if the value is empty OR if the same filter is already applied
        if (value && !this.appliedFilters.some(i => i.type === type && i.value === value)) {
          this.appliedFilters.push({
            type: type,
            label: value,
            value: value
          });
        }
        this.filters[type] = null;
      }
      this.lastFilteredItems = [];
      this.apply();
    }
    dropFilter(index) {
      const filter = this.appliedFilters[index];
      if (typeof filter.value === 'boolean') {
        this.filters[filter.type] = false;
      } else if (this.filters[filter.type] instanceof Object) {
        this.filters[filter.type][filter.value].checked = false;
      }
      this.appliedFilters.splice(index, 1);
      this.lastFilteredItems = [];
      this.apply();
    }
    apply() {
      this.filters = {
        ...this.filters
      };
      this.appliedFilters = [...this.appliedFilters];
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "filters", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "appliedFilters", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _applyDecoratedDescriptor(_class.prototype, "items", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "items"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "applyFilter", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "applyFilter"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "dropFilter", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "dropFilter"), _class.prototype)), _class));
});